import React, { useRef, useState } from 'react';
import Logo from "../assests/dynaptlogo.png"
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputOtp } from 'primereact/inputotp';
import Terms from './Terms';
import { useNavigate } from 'react-router-dom'; 
import { doCreateUserWithEmailAndPassword, doSendEmailVerification, doSignOut } from '../auth/firebase/auth';
import CryptoJS from 'crypto-js';
import { useAuth } from '../auth/authContext';
import { WHATSAPP_BASE_URL, BASE_URL } from '../utils/config';

function Signup() {
    let navigate = useNavigate();
    const { userLoggedIn, isEmailVerified } = useAuth();
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        organisation: '',
        password: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [OTP, setOTP] = useState('');
    const [otpSentToUser, setOtpSentToUser] = useState('0000');
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [isUserAgreed, setUserAgreement] = useState(false);
    const [showTnC, setShowTnC] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const toast = React.useRef(null);

    const openModal = () => {
        setShowModal(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        let hasErrors = false;
        let newErrors = {};

        if (!form.firstName) {
            hasErrors = true;
            newErrors.firstName = 'First name is required.';
        }
        if (!form.email) {
            hasErrors = true;
            newErrors.email = 'Email is required.';
        }
        if (!form.phone) {
            hasErrors = true;
            newErrors.phone = 'Phone number is required.';
        }
        if (!form.organisation) {
            hasErrors = true;
            newErrors.organisation = 'Organisation Name is required.';
        }
        if (form.password !== form.confirmPassword) {
            hasErrors = true;
            newErrors.confirmPassword = 'Passwords do not match.';
        }
        if (!form.password || !form.confirmPassword) {
            hasErrors = true;
            newErrors.confirmPassword = 'Password is required.';
        }

        if (!isUserAgreed) {
            hasErrors = true;
            newErrors.userAgreed = 'To continue please agree to the terms and conditions.';
        }

        setErrors(newErrors);
        return !hasErrors;
    };

    const sendOtp = () => {
        const phCode = '91';
        const random = Math.floor(Math.random() * 9000 + 1000);
        setOtpSentToUser(random);
        const reqToSend = {
            "receiver": `${phCode}${form.phone}@s.whatsapp.net`,
            "message": { "text": `Your otp is ${random}` }
        };

        fetch(`${WHATSAPP_BASE_URL}/chats/send?` + new URLSearchParams({
            id: 'device_8574435',
        }), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqToSend),
        }).then(() => {
            setShowModal(true);
            toast.current.show({ severity: 'success', summary: 'OTP Sent', detail: 'Check your phone for the OTP.' });
        }).catch((error) => {
            console.log(error);
        })     
       
    };

    const verifyOtp = () => {
        if (OTP == otpSentToUser) {
            setPhoneNumberVerified(true);
            setShowModal(false);
            toast.current.show({ severity: 'success', summary: 'Verified', detail: 'Phone number verified.' });
        } else {
            setErrorMessage('Incorrect OTP');
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Incorrect OTP.' });
        }
    };

    const encryptPassword = (password, encryptionKey) => {
        return CryptoJS.AES.encrypt(password, encryptionKey).toString();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!phoneNumberVerified) {
            setErrorMessage('Please verify your Phone Number');
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please verify your Phone Number.' });
            return;
        }
        if (validateForm()) {
            setIsRegistering(true);
            try {
                await sendUserInfo();
                await doCreateUserWithEmailAndPassword(form.email, form.password);
                await doSendEmailVerification();
                toast.current.show({ severity: 'success', summary: 'Registered', detail: 'Check your email to verify.' });
            } catch (error) {
                setErrorMessage(error.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            } finally {
                setIsRegistering(false);
                navigate("/");
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Check the input fields.' });
        }
    };

    const sendUserInfo = async () => {
        try {
            await fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/create_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({ 
                    CreatedBy: 1,
                    FirstName: form.firstName,
                    LastName: form.lastName,
                    Email: form.email,
                    MobilePhone: form.phone,
                    EncryptedPassword: encryptPassword(form.password, process.env.REACT_APP_Encryption_Key),
                    PhoneCode: '91',  
                    Organization: form.organisation
                }),
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleCheckbox = (e) => {
        setUserAgreement(e.target.checked);
        setShowTnC(e.target.checked);
    };

    return (
        <div className="login-container">
            <Toast ref={toast} />
            <Dialog header="Terms and Conditions" visible={showTnC} onHide={() => setShowTnC(false)} style={{ width: '50vw', height : '60vh' }}>
                <Terms/>
            </Dialog>
            <div className="Signup-card">
                <img src={Logo} alt="logo" height={50} className="mb-3" />
                <h1>Welcome to Dynapt Max</h1>
                <form onSubmit={handleSubmit}>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText name="firstName" value={form.firstName} onChange={handleChange} placeholder="First Name" type='text' />
                    </div>
                    {errors.firstName && <small className="p-error">{errors.firstName}</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText name="lastName" value={form.lastName} onChange={handleChange} placeholder="Last Name" />
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-at"></i>
                        </span>
                        <InputText name="email" value={form.email} onChange={handleChange} type='email' placeholder="Email" />
                    </div>
                    {errors.email && <small className="p-error">{errors.email}</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-phone"></i>
                        </span>
                        <InputText name="phone" value={form.phone} onChange={handleChange} type="text" placeholder="Phone Number" />
                        {form.phone !== "" && <Button type="button" label="Send OTP" onClick={sendOtp} />}
                    </div>
                    {errors.phone && <small className="p-error">{errors.phone}</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-warehouse"></i>
                        </span>
                        <InputText name="organisation" value={form.organisation} onChange={handleChange} type='text' placeholder="Organisation Name" />
                    </div>
                    {errors.organisation && <small className="p-error">{errors.organisation}</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <InputText name="password" value={form.password} onChange={handleChange} type='password' placeholder="Password" />
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <InputText name="confirmPassword" value={form.confirmPassword} onChange={handleChange} type='password' placeholder="Confirm Password" />
                    </div>
                    {errors.confirmPassword && <small className="p-error">{errors.confirmPassword}</small>}

                    <div className="p-inputgroup flex-1">
                        <input type="checkbox" 
                            onChange={handleCheckbox} 
                            checked={isUserAgreed}
                        />
                        <label>I agree with <span className="link" onClick={() => setShowTnC(true)}>Terms and Condition !</span></label>
                    </div>

                    <Button type="submit" className="SingUpButton" label="Sign Up" severity="success" disabled={isRegistering} />
                </form>
                <p>Already have an account? <span className="link" onClick={() => navigate('/login')}>Sign In !</span></p>
            </div>
            <Dialog header="Verify OTP" visible={showModal} onHide={() => setShowModal(false)}>
                <div>
                    <InputOtp value={OTP} onChange={(e) => setOTP(e.value)} numInputs={4} />
                    <Button label="Verify" onClick={verifyOtp} />
                </div>
            </Dialog>
        </div>
    );
}

export default Signup;
