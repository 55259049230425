const BASE_URL =  'https://dynaptmax-backend-fastapi-prod-wtnegfwvgq-el.a.run.app/bot_studio';
// const BASE_URL =  'https://8753-2409-40c4-100f-220d-9942-c370-f2e7-7a58.ngrok-free.app/bot_studio';

const WHATSAPP_BASE_URL = 'https://wa.dynaptsolutions.com:3000';
//const WHATSAPP_BASE_URL = 'http://127.0.0.1:5000';

// Export variables
module.exports = {
    BASE_URL,
    WHATSAPP_BASE_URL
};