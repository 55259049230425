import React, { useEffect, useState } from 'react';
import ChatBot from './avatar';
import { useAuth } from '../auth/authContext';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { doSignOut } from '../auth/firebase/auth';
import { useNavigate } from 'react-router-dom';
import Home from './Home';
import { WHATSAPP_BASE_URL, BASE_URL } from '../utils/config';

const PhoneVerificationPage = () => {
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCode, setPhoneCode] = useState('+91');
    const [otpValue, setOtpValue] = useState('');
    const [otpSentToUser, setOtpSentToUser] = useState('0000');
    const [sendingOtp, setSendingOtp] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [otpSendSuccessMessage, setOtpSendSuccessMessage] = useState('');

    const { currentUser } = useAuth();
    let navigate = useNavigate();

    const removePlusSymbol = (code) => {
        if (code.startsWith('+')) {
            return code.substring(1);
        }
        return code;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://dynaptmax-backend-fastapi-prod-wtnegfwvgq-el.a.run.app/helper_routers/get_user_by_email?email=${currentUser.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    },
                });
                const data = await response.json();
                
                if(response.ok){
                    setPhoneNumberVerified(true);
                }
                setLoading(false); 
            } catch (error) {
                setLoading(false); 
            }
        };

        fetchData();
    }, []);

    const sendUserInfo = async () => {
        const phCode = removePlusSymbol(phoneCode);
        const words = currentUser.displayName.split(' ');
        let firstName = words[0];
        let lastName = words.length > 1 ? words.slice(1).join(' ') : 'lastName';
        try {
            const response = await fetch('https://dynaptmax-backend-fastapi-prod-wtnegfwvgq-el.a.run.app/helper_routers/create_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({ 
                    CreatedBy: 1,
                    FirstName: firstName,
                    LastName: lastName,
                    Email: currentUser.email,
                    MobilePhone: phoneNumber,
                    EncryptedPassword: "Password",
                    PhoneCode: phCode,
                    Organization: "Dynapt"
                }),
            });

        } catch (error) {
            console.log(error);
        }
    };

    if (loading) {
        return <p>Loading...</p>; 
    }
   
    const sendOtp = (resend) => {
        const phCode = removePlusSymbol(phoneCode);
        if(phCode === ''){
            setPhoneErrorMessage('Enter Phone Code');
            return;
        }
        if(phoneNumber === ''){
            setPhoneErrorMessage('Enter Phone Number');
            return;
        }
        const phone = parsePhoneNumberFromString('+'+phCode+phoneNumber);
        if(!phone.isValid()){
            setPhoneErrorMessage('Enter a valid Phone Number');
            return;
        }

        setSendingOtp(true);
        const random = Math.floor(Math.random() * 9000 + 1000);
        setOtpSentToUser(random);
        const reqToSend = {
            "receiver": `${phCode}${phoneNumber}@s.whatsapp.net`,
            "message": { "text": `Your otp is ${random}` }
        };

        fetch(`${WHATSAPP_BASE_URL}/chats/send?` + new URLSearchParams({
            id: 'device_8574435',
        }), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqToSend),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Server response was not OK');
            }
            return response;
        })
        .then(() => {
            setOtpSent(true);
            setSendingOtp(false);
            if(!resend){setOtpSendSuccessMessage('OTP sent successfully.')}
            else{setOtpSendSuccessMessage('OTP resent successfully.');}
        }).catch((error) => {
            setSendingOtp(false);
            setPhoneErrorMessage('Failed to send OTP. Please retry.');
            console.log(error);
        })     
    };     
    
    const resendOtp = () => {
        setPhoneErrorMessage('');
        setOtpValue('');
        sendOtp(true);
    };
                                 
    const verifyOtp = async () => {
        setOtpSendSuccessMessage('');
        if(otpValue === ''){
            setPhoneErrorMessage("Enter OTP");
            return;
        }
        if(otpValue == otpSentToUser){
            await sendUserInfo();
            setPhoneNumberVerified(true);
        }
        else{
            setPhoneErrorMessage("Incorrect OTP");
        }
    };

    return (
        <div>
            {phoneNumberVerified ? (
                <Home />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div style={{ maxWidth: '400px', width: '100%', padding: '2rem', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                    <div>
                        <label style={{ fontSize: '0.875rem', color: '#4a5568', fontWeight: 'bold' }}>Verify your Phone Number</label>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                            <input
                                type="text"
                                autoComplete="off"
                                maxLength={4}
                                placeholder="+"
                                value={phoneCode}
                                onChange={(e) => { setPhoneCode(e.target.value); setPhoneErrorMessage(''); }}
                                style={{ width: '20%', padding: '0.5rem', color: '#718096', backgroundColor: 'transparent', outline: 'none', borderRadius: '0.375rem 0 0 0.375rem', transition: 'border-color 0.3s', border: '1px solid #cbd5e0', borderRight: '0' }}
                            />
                            <input
                                type="tel"
                                autoComplete="tel"
                                required
                                value={phoneNumber}
                                onChange={(e) => { setPhoneNumber(e.target.value); setPhoneErrorMessage(''); }}
                                style={{ width: '80%', padding: '0.5rem', color: '#718096', backgroundColor: 'transparent', outline: 'none', borderRadius: '0 0.375rem 0.375rem 0', transition: 'border-color 0.3s', border: '1px solid #cbd5e0', borderLeft: '0' }}
                            />
                            {!phoneNumberVerified && !otpSent && (
                                <button
                                    type="button"
                                    disabled={sendingOtp}
                                    onClick={() => sendOtp(false)}
                                    style={{ marginLeft: '0.5rem', padding: '0.5rem 1rem', backgroundColor: '#4f46e5', color: 'white', fontWeight: 'medium', borderRadius: '0.375rem', transition: 'background-color 0.3s', cursor: 'pointer', border: 'none' }}
                                >
                                    {sendingOtp ? 'Sending...' : 'Send OTP'}
                                </button>
                            )}
                        </div>
                        {otpSent && !phoneNumberVerified && (
                            <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    value={otpValue}
                                    onChange={(e) => { setOtpValue(e.target.value); setPhoneErrorMessage(''); setOtpSendSuccessMessage(''); }}
                                    style={{ width: '100%', padding: '0.5rem', color: '#718096', backgroundColor: 'transparent', outline: 'none', borderRadius: '0.375rem', transition: 'border-color 0.3s', border: '1px solid #cbd5e0' }}
                                />
                                <button
                                    type="button"
                                    onClick={verifyOtp}
                                    style={{ marginLeft: '0.5rem', padding: '0.5rem 1rem', backgroundColor: '#4f46e5', color: 'white', fontWeight: 'medium', borderRadius: '0.375rem', transition: 'background-color 0.3s', cursor: 'pointer', border: 'none' }}
                                >
                                    Verify OTP
                                </button>
                                <button
                                    type="button"
                                    onClick={resendOtp}
                                    style={{ marginLeft: '0.5rem', padding: '0.5rem 1rem', backgroundColor: '#4f46e5', color: 'white', fontWeight: 'medium', borderRadius: '0.375rem', transition: 'background-color 0.3s', cursor: 'pointer', border: 'none' }}
                                >
                                    Resend OTP
                                </button>
                            </div>
                        )}
                        {phoneNumberVerified && (
                            <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: 'green', marginRight: '0.5rem' }}>&#10003;</span>
                                <span style={{ color: '#4a5568' }}>Verified</span>
                            </div>
                        )}
                    </div>
                    {phoneErrorMessage && <span style={{ color: 'red', fontWeight: 'bold' }}>{phoneErrorMessage}</span>}
                    {otpSendSuccessMessage && <div style={{ color: 'green' }}>{otpSendSuccessMessage}</div>}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', padding: '1rem 0' }}>
                        <div style={{ borderTop: '2px solid #e2e8f0', flexGrow: '1', marginTop: '0.75rem', marginRight: '0.5rem' }}></div>
                        <div style={{ fontSize: '0.875rem', fontWeight: 'bold', padding: '0.5rem 0.5rem 0.75rem' }}>OR</div>
                        <div style={{ borderTop: '2px solid #e2e8f0', flexGrow: '1', marginTop: '0.75rem', marginLeft: '0.5rem' }}></div>
                    </div>
                    <div style={{ fontSize: '0.875rem', textAlign: 'center', marginTop: '0.5rem' }}>
                        Need to use a different account?
                        <button
                            onClick={() => {
                                doSignOut();
                                navigate('/login');
                            }}
                            style={{ marginLeft: '0.5rem', fontSize: '0.875rem', color: '#4f46e5', textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}
                        >
                            Sign in
                        </button>
                    </div>
                </div>
                </div>
            )}
        </div>
    );
};

export default PhoneVerificationPage;
